export enum AggrementType {
	none = "none",
	basic = "basic",
	plus = "plus",
	premium = "premium",
	upgradefrombasictopremium = "upgradefrombasictopremium",
	upgradefromplustopremium = "upgradefromplustopremium",
	pluspayforservice = "pluspayforservice",
	basicpayforalmserv = "basicpayforalmserv",
	premiumpayforalmserv = "premiumpayforalmserv"
}

export enum ModelType {
    none = "none",
    aqalife = "aqalife",
    bwtperla = "bwtperla",
    bwtperlahome = "bwtperlahome",
    aqabasic = "aqabasic",
    bwtperlaone = "bwtperlaone"
}