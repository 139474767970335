import React from 'react';
import XMark from './Icons/XMark';
import SwitchArrowRightLeft  from './Icons/SwitchArrowRightLeft';

interface TooltipProps {
	id: string;
	date: string;
	timeSlot: string;
	name: string;
	phone: string;
	postal: string;
	visible: boolean;
	project: string;
	orderNumber: string; 
	updatedAt: string;
	openModal: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ id, date, timeSlot, name, phone,  postal, visible, project, orderNumber, updatedAt, openModal}) => {
	return (
		<div
			className={`absolute bg-[#FF88BD] text-white p-2 rounded-md text-sm shadow-2xl whitespace-nowrap transition-opacity duration-150
			${visible ? 'opacity-100' : 'opacity-0 pointer-events-none'} z-50`}
			style={{ minWidth: '200px' }}
		>
			<button
				onClick={openModal}
				className="absolute top-0 right-0 hover:text-opacity-70 focus:outline-none bg-red-600 p-2 text-white rounded-tr text-sm"
				aria-label="Delete"
				>
				
				<XMark className='w-4 h-4' />
			</button>
			<button
				onClick={openModal}
				className="absolute top-8 right-0 hover:text-opacity-70 focus:outline-none bg-blue-400 p-2 text-white rounded-bl text-sm"
				aria-label="Delete"
				>
				
				<SwitchArrowRightLeft className='w-4 h-4' />
			</button>


			<div><strong>ID:</strong> {id}</div>
			<div><strong>Dato:</strong> {date}</div>
			<div><strong>Tid:</strong> {timeSlot}</div>
			<div><strong>Navn:</strong> {name}</div>
			<div><strong>Telefon:</strong> {phone}</div>
			<div><strong>Postnummer:</strong> {postal}</div>
			<div><strong>Projekt:</strong> {project}</div>
			<div><strong>Ordrenr.:</strong> {orderNumber}</div>
			<div><strong>Sidst opdateret.:</strong> {updatedAt}</div>

		</div>
	);
};

export default Tooltip;