import React, { useState, useEffect } from "react";
import axios from "axios";
import Tooltip from "../Components/Tooltip";
import { IWeeks, IUserWorkWeek } from "../types/calendar.view.types";
import ModalSimple from "../Components/ModalSimple";

// const API = "https://integration.bwt.dk/api";
const API = "http://localhost:8080/api";

const CalendarView: React.FC = () => {

	const [year, setYear] = useState(new Date().getFullYear().toString());
	const [week, setWeek] = useState<string>("");

	const [years, setYears] = useState<number[]>([]);
	const [weeks, setWeeks] = useState<IWeeks[]>([]);

	const [from, setFrom] = useState("");
	const [to, setTo] = useState("");

	useEffect(() => {
		console.log("The current week is " + getISOWeekNumber())
		setWeek(getISOWeekNumber().toString());
		getAllYearsOfBookings();
		getAllWeeksOfBookings();
		getFromAndToDate();
	}, []);

	useEffect(() => {
		getAllWeeksOfBookings();
	}, [year]);

	useEffect(() => {
		getFromAndToDate();
	}, [from, to, week, weeks]);

	const getISOWeekNumber = (date: Date = new Date()): number => {
		// Create a copy of the date at the beginning of the day in UTC
		const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
	
		// Find the nearest Thursday (to comply with ISO 8601), as weeks start from Monday
		const dayOfWeek = (currentDate.getUTCDay() + 6) % 7; // Shift Sunday (0) to the end of the week
		currentDate.setUTCDate(currentDate.getUTCDate() - dayOfWeek + 3); // Move to Thursday
	
		// Calculate the first ISO week of the year
		const firstThursday = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 4));
		const firstDayOfWeek = (firstThursday.getUTCDay() + 6) % 7;
		firstThursday.setUTCDate(firstThursday.getUTCDate() - firstDayOfWeek + 3);
	
		// Calculate the week number
		const weekNumber = Math.ceil(((currentDate.getTime() - firstThursday.getTime()) / 86400000 + 1) / 7);
		
		return weekNumber;
	};

	const getAllYearsOfBookings = async () => {
		try {
			const response = await axios.get(`${API}/technicianbooking/years`);
			console.log(response.data);
			const y = response.data.years.map((year: any) => { return year.year });
			setYears(y);
		} catch (error) {
			console.log(error);
		}
	}

	const getAllWeeksOfBookings = async () => {
		try {
			const response = await axios.get(`${API}/technicianbooking/weeks/${year}`);
			console.log(response.data);
			setWeeks(response.data.weeks);
		} catch (error) {
			console.log(error);
		}
	}

	const getFromAndToDate = async () => {

		if (year === "" || week === "") {
			console.log("Year or week is empty");
			return;
		}

		if (weeks.length === 0) {
			console.log("Weeks is empty");
			return;
		}

		console.log(year, week);
		console.log(weeks);
		
		let obj = weeks.find((w: IWeeks) => w.weeknumber.toString() === week);
		console.log(obj);

		if (obj) {
			console.log("Setting from and to");
			setFrom(obj.from);
			setTo(obj.to);	
		} else {
			console.log("week not found in weeks");
		}
	}

	return (
		<div className="">
			{/* NAVBAR */}
			<div className="bg-[#00174B] text-white py-4">
				<h3 className="text-sm font-semibold md:text-2xl text-center">Bookinger via booking.bwt.dk</h3>
				<div className="mx-10 mt-2 grid grid-cols-5 gap-4">
					<div>
						<label className="w-1/3 pr-1" htmlFor="År">År</label>
						<select className="border border-gray-400 p-1 w-2/3 text-[#00174B] rounded" name="years" id="years" value={year} onChange={e => setYear(e.target.value)}>
							{years.map((y: any) => (
								<option selected={y === year} key={y} value={y}>{y}</option>
							))}
						</select>
					</div>
					<div>
						<label className="w-1/3 pr-1" htmlFor="Måned">Uge</label>
						<select className="border border-gray-400 p-1 w-2/3 text-[#00174B] rounded" name="weeks" id="weeks" value={week} onChange={e => setWeek(e.target.value)}>
							{weeks.map((w: any) => (
								<option selected={w.weeknumber === week} key={w.weeknumber} value={w.weeknumber}>{w.weeknumber}</option>
							))}
						</select>
					</div>
					<p className="text-sm md:text-base text-center">Uge overblik</p>
				</div>
			</div>
			<UserWorkView userId={220} from={from} to={to} name="Anders Lindskov Frederiksen" />
			<UserWorkView userId={148} from={from} to={to} name="Jesper Rundkvist" />
			<UserWorkView userId={228} from={from} to={to} name="Kasper Højlund Hansen" />
			<UserWorkView userId={243} from={from} to={to} name="Mark Sander Angelsø" />
			<UserWorkView userId={159} from={from} to={to} name="Lars Seneca" />
			<UserWorkView userId={225} from={from} to={to} name="Ole Flemming Hansen" />

			
			<div>
				{/*<div className="mx-10 mt-2">*/}
					{/*
					<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">Usama Ahmed</h3>

					<div className="grid grid-cols-5 gap-1">
						<div className="border-1 border-slate-500 col-span-1">
							<p className="text-center font-mono font-semibold py-2 col-span-12">Mandag</p>	
							<div className="grid grid-cols-12 gap-1 grid-child">
								<div className={`col-start-1 col-span-6 bg-orange-600 text-gray-100 px-2 py-2 font-mono text-sm rounded relative`}>
									<div>
										Usama test
									</div>
									<div>
										Ledig
									</div>
								</div>
								<div className={`col-start-1 col-span-6 bg-orange-600 text-gray-100 px-2 py-2 font-mono text-sm rounded relative`}>
									<div>
										Usama test
									</div>
									<div>
										Ledig
									</div>
								</div>
								<div className={`col-start-7 row-start-1 col-span-6 bg-orange-600 text-gray-100 px-2 py-2 font-mono text-sm rounded relative`}>
									<div>
										Usama test
									</div>
									<div>
										Ledig
									</div>
								</div>
								<div className={`col-start-7 row-start-2 col-span-6 bg-orange-600 text-gray-100 px-2 py-2 font-mono text-sm rounded relative`}>
									<div>
										Usama test
									</div>
									<div>
										Ledig
									</div>
								</div>
								<div className={`col-start-1 row-start-2 col-span-6 bg-orange-600 text-gray-100 px-2 py-2 font-mono text-sm rounded relative`}>
									<div>
										Usama test
									</div>
									<div>
										Ledig
									</div>
								</div>
							</div>
						</div>
						<div className="border-1 border-slate-500 col-span-1">
							<p className="text-center font-mono font-semibold py-2 col-span-12">Tirsdag</p>	
							<div className="grid grid-cols-12 gap-1 grid-child">
							</div>
						</div>
						<div className="border-1 border-slate-500 col-span-1">
							<p className="text-center font-mono font-semibold py-2 col-span-12">Onsdag</p>	
							
							<div className="grid grid-cols-12 gap-1 grid-child">
							</div>
						</div>
						<div className="border-1 border-slate-500 col-span-1">
							<p className="text-center font-mono font-semibold py-2 col-span-12">Torsdag</p>	
							<div className="grid grid-cols-12 gap-1 grid-child">
							</div>
						</div>
						<div className="border-1 border-slate-500 col-span-1">
							<p className="text-center font-mono font-semibold py-2 col-span-12">Fredag</p>	
							<div className="grid grid-cols-12 gap-1 ">
							</div>
						</div>
					</div>*/}
				{/*</div>*/}
			</div>
		</div>
	)
};

const UserWorkView: React.FC<IUserWorkWeek> = ({userId, name, from, to}) => {

	const [monOrders, setMonOrders] = useState([]);
	const [tueOrders, setTueOrders] = useState([]);
	const [wedOrders, setWedOrders] = useState([]);
	const [thuOrders, setThuOrders] = useState([]);
	const [friOrders, setFriOrders] = useState([]);
	const [mondayDate, setMondayDate] = useState([]);
	const [tuesdayDate, setTuesdayDate] = useState([]);
	const [wednesdayDate, setWednesdayDate] = useState([]);
	const [thursdayDate, setThursdayDate] = useState([]);
	const [fridayDate, setFridayDate] = useState([]);

	useEffect(() => {

		if (from === "" || to === "") {
			return;
		}	

		getOrdersByFromAndToDate();

	}, [from, to]);

	const getOrdersByFromAndToDate = async () => {
		try {
			
			const res = await axios.get(`${API}/technicianbooking/weekly/${userId}/${from}/${to}`);

			console.log(res.data);

			let mon:any = [];
			let tue:any = [];
			let wed:any = [];
			let thu:any = [];
			let fri:any = [];

			let monRow:number = 1;
			let tueRow:number = 1;
			let wedRow:number = 1;
			let thuRow:number = 1;
			let friRow:number = 1;

			let curDate:string = "";

			res.data.bookings.forEach((order: any) => {

				order.colSpan = "6";
				order.bgColor = "bg-green-500";
				order.rowStart = "";
				order.colStart = "";

				if (order.date !== curDate) {
					curDate = order.date;
					monRow = 1;
					tueRow = 1;
					wedRow = 1;
					thuRow = 1;
					friRow = 1;
				}

				console.log(typeof order.timeSlot);

				if (order.timeSlot === "07:00-11:00") {
					order.colStart = "col-start-1";
				}
				if (order.timeSlot === "11:00-15:00") {
					order.colStart = "col-start-7";
				} 
				if (order.timeSlot === "07:00-15:00") {
					order.colStart = "col-start-1";
					order.colSpan = "12";
				}

				if (order.available === false) {
					order.bgColor = "bg-[#00174B]";
				}

				if (order.source === "blocked") {
					order.bgColor = "bg-gray-400";
				}

				if (order.dayOfWeek === 2) {
					setMondayDate(order.date);
					if (order.timeSlot === "11:00-15:00") {
						order.rowStart = "row-start-" + monRow;
						order.colStart = "col-start-7";
						monRow++;
					} 
					if (order.timeSlot === "07:00-11:00") {
						order.colStart = "col-start-1";
					}
					mon.push(order);
				} else if (order.dayOfWeek === 3) {
					setTuesdayDate(order.date);
					if (order.timeSlot === "11:00-15:00") {
						order.rowStart = "row-start-" + tueRow;
						order.colStart = "col-start-7";
						tueRow++;
					}
					if (order.timeSlot === "07:00-11:00") {
						order.colStart = "col-start-1";
					}
					tue.push(order);
				} else if (order.dayOfWeek === 4) {
					setWednesdayDate(order.date);
					if (order.timeSlot === "11:00-15:00") {
						order.rowStart = "row-start-" + wedRow;
						order.colStart = "col-start-7";
						wedRow++;
					}
					if (order.timeSlot === "07:00-11:00") {
						order.colStart = "col-start-1";
					}
					wed.push(order);
				} else if (order.dayOfWeek === 5) {
					setThursdayDate(order.date);
					if (order.timeSlot === "11:00-15:00") {
						order.rowStart = "row-start-" + thuRow;
						order.colStart = "col-start-7";
						thuRow++;
					}
					if (order.timeSlot === "07:00-11:00") {
						order.colStart = "col-start-1";
					}
					thu.push(order);
				} else if (order.dayOfWeek === 6) {
					setFridayDate(order.date);
					if (order.timeSlot === "11:00-15:00") {
						order.rowStart = "row-start-" + friRow;
						order.colStart = "col-start-7";
						friRow++;
					}
					if (order.timeSlot === "07:00-11:00") {
						order.colStart = "col-start-1";
					}
					fri.push(order);
				}
			});

			setMonOrders(mon);
			setTueOrders(tue);
			setWedOrders(wed);
			setThuOrders(thu);
			setFriOrders(fri);

			console.log(mon);
			console.log(tue);
			console.log(wed);
			console.log(thu);
			console.log(fri);

		} catch (error) {
			console.log(error);
		}
	} 

    return (
		<div className="mx-4 mt-2">

			<h3 className="text-sm font-semibold md:text-2xl text-[#00174B]">{name}</h3>
			{/* <h4 className="text-sm font-semibold md:text-sm text-[#00174B]">Uge 45</h4> */}

			<div className="grid grid-cols-5 gap-1">
				<div className="border-1 border-slate-500 col-span-1">
					<TableDay day={"Mandag, " + mondayDate } />
					<div className="grid grid-cols-12 gap-1 grid-child">
						{monOrders.map((order: any) =>  (
							<OrderView key={order.id} cn={`${order.colStart} col-span-${order.colSpan} ${order.rowStart}`} order={order} />
						))}
					</div>
				</div>
				<div className="border-1 border-slate-500 col-span-1">
					<TableDay day={"Tirsdag, " + tuesdayDate} />
					<div className="grid grid-cols-12 gap-1 grid-child">
						{tueOrders.map((order: any) =>  (
							<OrderView key={order.id} cn={`${order.colStart} col-span-${order.colSpan} ${order.rowStart}`} order={order} />
						))}
					</div>
				</div>
				<div className="border-1 border-slate-500 col-span-1">
					<TableDay day={"Onsdag, " + wednesdayDate} />
					
					<div className="grid grid-cols-12 gap-1 grid-child">
						{wedOrders.map((order: any) =>  (
							<OrderView key={order.id} cn={`${order.colStart} col-span-${order.colSpan} ${order.rowStart}`} order={order} />
						))}
					</div>
				</div>
				<div className="border-1 border-slate-500 col-span-1">
					<TableDay day={"Torsdag, " + thursdayDate} />
					<div className="grid grid-cols-12 gap-1 grid-child">
						{thuOrders.map((order: any) =>  (
							<OrderView key={order.id} cn={`${order.colStart} col-span-${order.colSpan} ${order.rowStart}`} order={order} />
						))}
					</div>
				</div>
				<div className="border-1 border-slate-500 col-span-1">
					<TableDay day={"Fredag, " + fridayDate} />
					<div className="grid grid-cols-12 gap-1 ">
					{friOrders.map((order: any) =>  (
							<OrderView key={order.id} cn={`${order.colStart} col-span-${order.colSpan} ${order.rowStart}`} order={order} />
						))}
					</div>
				</div>
			</div>
		</div>
    )
}

const TableDay: React.FC<{day:string}> = ({day}) => {
	return (
		<p className="text-center font-mono font-semibold col-span-12">{day}</p>	
	)
}

const OrderView: React.FC<{cn:any; order:any}> = ({ cn, order}) => {
	const [tooltipVisible, setTooltipVisible] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleDelete = (id:number) => {
		if (window.confirm("Er du sikker på at du vil slette denne booking?")) {
			console.log("Delete action confirmed for id: " + id);
			setIsModalOpen(false); // Close the modal after delete

			axios.delete(`${API}/technicianbooking/delete/${id}`).then((res) => {
				console.log(res.data);
				console.log("Booking deleted with id: " + id);
			}).catch((error) => {
				console.log(error);
			});
		} else {
			console.log("Delete action cancelled for id: " + id);
		}
	};

	const handleReset = (id:number) => {
		if (window.confirm("Er du sikker på at du vil frigive denne booking?")) {
			console.log("Reset action confirmed id: " + id);
			setIsModalOpen(false); // Close the modal after reset

			axios.post(`${API}/technicianbooking/release/${id}`).then((res) => {
				console.log(res.data);
				console.log("Booking released with id: " + id);
			}).catch((error) => {
				console.log(error);
			});
		} else {
			console.log("Delete action cancelled for id: " + id);
		}
	}

	const showTooltip = (event: React.MouseEvent) => {	
		setTooltipVisible(true);
	};

	const hideTooltip = () => {
		setTooltipVisible(false);
	};

	return (
		<div
			className={`${cn} ${order.bgColor} text-gray-100 px-2 py-1 font-mono text-xs rounded relative`}
			onMouseEnter={showTooltip}
			onMouseMove={showTooltip}
			onMouseLeave={hideTooltip}
		>
			<div>
				{order.orderId} {order.firstName ? "/" + order.firstName : ""}
			</div>
			<div>
				{order.source === "blocked" ? "Blokeret" : order.source === "auto" && order.available === true ? "Ledig" : order.source}
			</div>

      		{/* Tooltip Component */}
			<Tooltip
				id={order.id}
				date={order.date}
				timeSlot={order.timeSlot}
				name={order.firstName}
				phone={order.phone}
				postal={order.postal}
				visible={tooltipVisible}
				project={order.projectId}
				orderNumber={order.orderId}
				updatedAt={order.updatedAt}
				openModal={handleOpenModal}
			/>
			<ModalSimple
				isOpen={isModalOpen}
				title="Bekræft handling"
				onClose={handleCloseModal}
				onDelete={() => handleDelete(order.id)}
				onReset={() => handleReset(order.id)}
			>
				<p className="text-[#00174B] mb-2">Denne handling kan ikke trækkes tilbage!</p>
				<p className="text-[#00174B] mb-2"><span className="bg-[#00174B] text-white p-1">Frigiv</span> - Benyttes hvis tiden skal gøres tilgængelig igen</p>
				<p className="text-[#00174B] mb-2"><span className="bg-red-600 text-white p-1">Slet</span> - Benyttes oftest hvis en blokering skal slettes</p>
			</ModalSimple>
    	</div>
	)
}


export default CalendarView;