import React, { FormEvent } from "react";
import TextInput from "./TextInput";
import { FormData } from "../types/types";
import Checkbox from "../Components/Checkbox";
import { AggrementType } from "../types/enums";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	formData: FormData;
	date: Date | null;
	time: String | null;
	onSubmit: (e: FormEvent) => void;
	isLoading: boolean;
	setFormData: React.Dispatch<React.SetStateAction<FormData>>;
	getAggrementPrice: (model: string, aggrement: string) => number;
	isEligibleForFreeServiceAggrement: () => boolean;
	priceUpgPlusPrem:number;
	priceUpgBasicPrem:number;
	pricePlusServ:number;
	pricePremiumServ:number;
	priceBasicServ:number;
}

// Min Modal
const Modal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	formData,
	date,
	time,
	onSubmit,
	isLoading,
	setFormData,
	getAggrementPrice,
	isEligibleForFreeServiceAggrement,
	priceUpgPlusPrem,
	priceUpgBasicPrem,
	pricePlusServ,
	pricePremiumServ,
	priceBasicServ
}) => {
	function formatDate(date: Date) {
		let day: any = date.getDate();
		let month: any = date.getMonth() + 1; // Month is zero-based
		let year: any = date.getFullYear() + 2;

		// Add leading zero if day or month is less than 10
		day = day < 10 ? "0" + day : day;
		month = month < 10 ? "0" + month : month;

		return day + "-" + month + "-" + year;
	}

	function capitalizeFirstLetter(string: string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<>
			{isOpen && (
				<div className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-screen">
						<div className="fixed inset-0 transition-opacity" onClick={onClose}>
							<div className="absolute inset-0 bg-black opacity-50"></div>
						</div>
						<div className="relative bg-white py-5 md:py-10 px-2 md:px-8 rounded-lg max-w-2xl">
							<div className="justify-between font-semibold text-gray-900 flex items-center transition-all duration-300">
								<p className="text-sm md:text-xl font-semibold">Bekræft venligst at nedenstående er korrekt</p>
								<button className="text-gray-600 hover:text-gray-900 focus:outline-none" onClick={onClose}>
									X
								</button>
							</div>
							<div className="mt-4">
								<div className={`text-gray-600`}>
									<form>
										<div className="grid grid-cols-2 gap-2">
											<div className="mb-3 md:mb-4">
												<TextInput
													name="Fornavn"
													value={formData.contactDetails.firstName}
													disabled={true}
													onChange={() => {}}
													inputName="firstName"
													className="w-full border p-2 text-sm md:text-base"
													formIndex="contactDetails"
												/>
											</div>
											<div className="mb-3 md:mb-4">
												<TextInput
													name="Efternavn"
													value={formData.contactDetails.lastName}
													disabled={true}
													onChange={() => {}}
													inputName="lastName"
													className="w-full border p-2 text-sm md:text-base"
													formIndex="contactDetails"
												/>
											</div>
										</div>
										<div className="mb-3 md:mb-4">
											<TextInput
												name="Email"
												value={formData.contactDetails.email}
												disabled={true}
												onChange={() => {}}
												inputName="email"
												className="w-full border p-2 text-sm md:text-base"
												formIndex="contactDetails"
											/>
										</div>
										<div className="mb-3 md:mb-4">
											<label className="block text-gray-700 text-sm font-bold	">Telefonnummer</label>
											<div className="grid grid-cols-10">
												<input
													type="text"
													name="phone"
													value="+45"
													disabled
													className="col-span-2 md:cols-span-1 w-full border p-2 text-sm md:text-base"
												/>
												<input
													type="text"
													name="phone"
													value={formData.contactDetails.phone}
													disabled
													className="col-span-8 md:cols-span-9 w-full border p-2 text-sm md:text-base"
												/>
											</div>
										</div>
										<div className="mb-3 md:mb-4">
											<TextInput
												name="Adresse"
												value={formData.contactDetails.address}
												disabled={true}
												onChange={() => {}}
												inputName="address"
												className={`w-full p-2 border text-sm md:text-base`}
												formIndex="contactDetails"
											/>
										</div>
										<div className="grid grid-cols-2 gap-2">
											<div className="mb-3 md:mb-4">
												<TextInput
													name="By"
													value={formData.contactDetails.city}
													disabled={true}
													onChange={() => {}}
													inputName="city"
													className="w-full border p-2 text-sm md:text-base"
													formIndex="contactDetails"
												/>
											</div>
											<div className="mb-3 md:mb-4">
												<TextInput
													name="Postnummer"
													value={formData.contactDetails.postalCode}
													disabled={true}
													onChange={() => {}}
													inputName="postalCode"
													className={`w-full border p-2 text-sm md:text-base`}
													formIndex="contactDetails"
												/>
											</div>
										</div>
									</form>

									{date ? (
										<p>
											Dato <b>{date.toISOString().split("T")[0]}</b> Tid <b>{time}</b>
										</p>
									) : null}
									
									{formData.orderDetails.wantServiceAggrement === AggrementType.none ? (
										<>
											<p>Du har valgt at betale for besøget, såfremt det ikke er dækket af garantien.</p>
											<p>Pris for standard servicebesøg er fra 1.995 kr. inkl. Moms.</p>
										</>
									) : null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.premium || formData.orderDetails.wantServiceAggrement === AggrementType.plus ? (
										<>
											{isEligibleForFreeServiceAggrement() && formData.installationDetails.installationDate ? (
												<p>
													Du har valgt at tegne en gratis BWT Tryghed Premium aftale som dækker betalingen af dette besøg. Aftalen er gratis til
													og med {formatDate(formData.installationDetails.installationDate)} hvorefter du vil blive opkrævet{" "}
													{getAggrementPrice(formData.installationDetails.model, formData.orderDetails.wantServiceAggrement)} kr. pr. måned.
												</p>
											) : (
												<>
													<p>
														Du har valgt at tegne en BWT Tryghed {capitalizeFirstLetter(formData.orderDetails.wantServiceAggrement)} for{" "}
														{getAggrementPrice(formData.installationDetails.model, formData.orderDetails.wantServiceAggrement)} kr. pr. måned
													</p>
												</>
											)}
										</>
									) : null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.upgradefromplustopremium ? (
										<>
											<p>
												Du har valgt at opgradere din BWT Tryghed Plus til en BWT Tryghed Premium aftale. Du vil blive opkrævet {priceUpgPlusPrem} kr. for opgraderingen. 
												Dit service besøg vil være inkluderet i opgraderingen.
											</p>
										</>
									):null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.upgradefrombasictopremium ? (
										<>
											<p>
												Du har valgt at opgradere din BWT Tryghed Basic til en BWT Tryghed Premium aftale. Du vil blive opkrævet {priceUpgBasicPrem} kr. for opgraderingen. 
												Dit service besøg vil være inkluderet i opgraderingen.
											</p>
										</>
									):null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.pluspayforservice ? (
										<>
											<p>
												Du har valgt at betale for dit servicebesøg til en reduceret pris på {pricePlusServ} kr. inkl. moms.
											</p>
										</>
									):null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.basicpayforalmserv ? (
										<>
											<p>
												Du har valgt at betale for dit servicebesøg til en reduceret pris på {priceBasicServ} kr. inkl. moms.
											</p>
										</>
									):null}

									{formData.orderDetails.wantServiceAggrement === AggrementType.premiumpayforalmserv ? (
										<>
										<p>
												Du har valgt at betale for dit servicebesøg til en reduceret pris på {pricePremiumServ} kr. inkl. moms.
											</p>
										</>
									):null}

									<Checkbox
										title="Jeg accepterer betingelserne og ovenstående oplysninger er korrekte"
										value={formData.orderDetails.acceptedDetailsAreCorrect ? true : false}
										onChange={() => {
											setFormData((prev) => ({
												...prev,
												orderDetails: { ...prev.orderDetails, acceptedDetailsAreCorrect: !prev.orderDetails.acceptedDetailsAreCorrect },
											}));
										}}
									/>

									<button className="text-white px-4 py-2 mr-4 rounded bg-[#666B6E]" onClick={onClose}>
										Annuller
									</button>

									{isLoading ? (
										<div className="loader w-10 h-10" />
									) : (
										<button
											disabled={date === null || time === null || !formData.orderDetails.acceptedDetailsAreCorrect} // If date and time is not selected, then disable the button
											type="button"
											className="text-white px-4 py-2 rounded bg-[#00174B] disabled:bg-[#666B6E] mt-2"
											onClick={onSubmit}
										>
											Bestil
										</button>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Modal;
