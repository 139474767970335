import React from 'react';

interface ModalProps {
	isOpen: boolean;
	title: string;
	onClose: () => void;
	onDelete: () => void;
	onReset: () => void;
	children: React.ReactNode;
}

const ModalSimple: React.FC<ModalProps> = ({ isOpen, title, onClose, onDelete, onReset, children }) => {
	if (!isOpen) return null;

	return (
	<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
		<div className="bg-white w-full max-w-lg p-6 rounded shadow-lg">
			{/* Modal Header */}
			<div className="flex justify-between items-center border-b pb-3">
				<h2 className="text-xl font-semibold text-[#00174B]">{title}</h2>
				<button onClick={onClose} className="text-gray-500 hover:text-gray-700 text-xl">
				&times;
				</button>
			</div>

			{/* Modal Body */}
			<div className="my-4">
				{children}
			</div>

			{/* Modal Footer */}
			<div className="flex justify-end space-x-4 mt-6">
				<button onClick={onClose} className="px-4 py-2 rounded bg-gray-300 text-gray-700 hover:bg-opacity-70">
					Annuller
				</button>
				<button onClick={onReset} className="px-4 py-2 rounded bg-[#00174B] text-white hover:bg-opacity-70">
					Frigiv
				</button>
				<button onClick={onDelete} className="px-4 py-2 rounded bg-red-600 text-white hover:bg-opacity-70">
					Slet
				</button>
			</div>
		</div>
	</div>
	);
};

export default ModalSimple;